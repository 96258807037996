import { setDisplayFlex, setDisplayNone } from '../utils'

export const addTbybListeners = () => {
  const tbybBackground = document.querySelector('.tbyb__background')
  const tbybButtons = document.querySelectorAll('.tbyb-button')
  const tbybContainer = document.querySelector('.tbyb__container')
  const tbybClose = document.querySelector('.tbyb__close')

  let tbybShowing = false

  const openTbyb = () => {
    setDisplayFlex(tbybBackground)
    tbybShowing = true
  }

  const closeTbyb = () => {
    setDisplayNone(tbybBackground)
    tbybShowing = false
  }

  document.addEventListener('click', (event) => {
    const withinBoundaries = event.composedPath().includes(tbybContainer)
    const withinButton = Array.from(tbybButtons).some((button) =>
      event.composedPath().includes(button)
    )
    const withinClose = event.composedPath().includes(tbybClose)

    if (withinClose) {
      closeTbyb()
      return
    }

    if (tbybShowing) {
      if (!withinBoundaries) {
        closeTbyb()
      }
    } else if (withinButton) {
      openTbyb()
    }
  })
}
