'use strict'
import React from 'react'
import ReactDOM from 'react-dom'

const e = React.createElement

class TestComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { liked: false }
  }

  render() {
    return e('div', null, 'i am right here')
  }
}

const domContainer = document.querySelector('#testcontainer')
if (domContainer) {
  ReactDOM.render(e(TestComponent), domContainer)
}

;<script src="https://diaco-developers.s3.amazonaws.com/frontend-testing/component.jsx"></script>
