import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'
import useMedia from '../utils/useMediaQuery'

const TooltipContainer = styled.div`
  position: relative;
`

const ContentStyles = styled.div`
  display: ${(props) => (props.status === 'hide' ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 11px rgba(38, 38, 38, 0.25);
  opacity: 1;
  top: 50px;
  left: ${(props) => props.leftPosition}px;
  padding: 12px;

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: ${(props) => props.leftPosition * -1 + 15}px;
    margin-left: -12px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`

let timeout = null

const handleTooltipPosition = (tooltip) => {
  const tooltipRect = tooltip.getBoundingClientRect()
  let left = `${tooltip.offsetLeft}`
  const tooltipRight = tooltipRect.right
  const windowWidth = window.innerWidth
  const tooltipOffset = 40

  if (tooltipRight > windowWidth) {
    left = `${windowWidth - tooltipRight - tooltipOffset}`
  }

  return left
}

const Tooltip = ({ children, show }) => {
  const [status, setStatus] = useState(show ? 'display' : 'hide')
  const isMobile = useMedia(
    {
      '(max-width: 1024px)': true,
    },
    false
  )

  useEffect(() => {
    if (show) {
      setStatus('display')
    } else {
      setStatus('hide')
    }
  }, [show])

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child) && typeof child.type !== 'string') {
      return cloneElement(child, { status, setStatus })
    }
    return null
  })

  const toggleTooltip = () => {
    if (status === 'fading') {
      setStatus('display')
      clearTimeout(timeout)
    } else if (status === 'hide') {
      setStatus('display')
    } else {
      setStatus('fading')
      timeout = setTimeout(() => setStatus('hide'), 200)
    }
  }

  return (
    <TooltipContainer
      className="k-z-tooltip"
      onClick={isMobile ? toggleTooltip : () => null}
      onMouseEnter={!isMobile ? toggleTooltip : () => null}
      onMouseLeave={toggleTooltip}
    >
      {childrenWithProps}
    </TooltipContainer>
  )
}

const Content = ({ children, status, className }) => {
  const ref = useRef()
  const [leftPosition, setLeftPosition] = useState('-20')

  useEffect(() => {
    if (status === 'display' && ref.current) {
      setLeftPosition(handleTooltipPosition(ref.current))
    }
  }, [status])

  return (
    <ContentStyles
      ref={ref}
      className={className}
      status={status}
      leftPosition={leftPosition}
    >
      {children}
    </ContentStyles>
  )
}
Content.displayName = 'TooltipContent'

const Trigger = ({ children }) => {
  return <>{children}</>
}

const TooltipNamespace = Object.assign(Tooltip, { Trigger, Content })

export default TooltipNamespace
