import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const SpinnerContainer = styled(
  'div',
  `
  svg {
    animation: 2s linear infinite svg-animation;
    max-width: ${({ width }) => (width ? width.toString() : '100')}px;
    height: 100px;
  }

  // SVG animation.
  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  // Circle styles.
  circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: #e6e6e6;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
  }

  // Circle animation.
  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`
)

const AnimatedSpinner = ({ width }) => (
  <SpinnerContainer width={width}>
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="45" />
    </svg>
  </SpinnerContainer>
)

AnimatedSpinner.propTypes = {
  width: PropTypes.number,
}

AnimatedSpinner.defaultProps = {
  width: 100,
}

export default AnimatedSpinner
