const basePath = '//d2mufk0wtzg9ln.cloudfront.net/web/'

const silverStar = `${basePath}silver-star.png`

const goldStar = `${basePath}gold-star.png`

const platinumStar = `${basePath}platinum-star.png`

const REWARDS_ICONS = {
  silver: silverStar,
  gold: goldStar,
  platinum: platinumStar,
}

export const getRewardsIcon = (tier) => {
  return REWARDS_ICONS[tier.toLowerCase()]
}
