import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useMedia from '../utils/useMediaQuery'
import AutocompleteSuggestion from './AutocompleteSuggestion'
import { Close } from './icons'
import PopularSearches from './PopularSearches'
import ProductCard from './ProductCard'
import SearchInput from './SearchInput'

const popularSearches = [
  { name: 'Jeans', href: 'collections/jeans' },
  { name: 'Dresses', href: 'collections/dresses' },
  { name: 'Cardigans', href: 'collections/sweaters-and-cardigans' },
  { name: 'Shorts', href: 'collections/shorts' },
]

const SearchBar = ({
  baseUrlShop,
  storefrontAPIKey,
  storefrontAPIPath,
  searchSpringAPIUrl,
  searchSpringSiteId,
}) => {
  const [options, setOptions] = useState([])
  const [query, setQuery] = useState('')
  const [correctedQuery, setCorrectedQuery] = useState('')
  const [suggestedItems, setSuggestedItems] = useState([])
  const [productResults, setProductResults] = useState([])
  const [redirectURL, setRedirectURL] = useState('')

  const isMobile = useMedia(
    {
      '(max-width: 1024px)': true,
    },
    false
  )

  const suggestedItemsQuery = new URLSearchParams({
    siteId: searchSpringSiteId,
    resultsFormat: 'native',
    'bgfilter.ss_is_published': 1,
    resultsPerPage: 4,
  })

  const handleKeypress = (e) => {
    // hits enter button
    if (e.charCode === 13) {
      if (redirectURL) {
        window.location.assign(redirectURL)
      } else if (
        correctedQuery &&
        correctedQuery.toLowerCase() !== query.toLowerCase()
      ) {
        window.location.assign(
          `${baseUrlShop}/search/?q=${encodeURIComponent(
            correctedQuery
          )}&oq=${encodeURIComponent(query)}`
        )
      } else {
        window.location.assign(
          `${baseUrlShop}/search/?q=${encodeURIComponent(query)}`
        )
      }
    }
  }

  const checkCustomRedirect = useCallback((data = {}) => {
    const { merchandising = {} } = data
    const { redirect } = merchandising
    setRedirectURL(redirect)
  })

  const getAutocomplete = useCallback(async () => {
    const response = await fetch(
      `${searchSpringAPIUrl}suggest/query?siteId=${searchSpringSiteId}&query=${encodeURIComponent(
        query
      )}`
    )
    const data = await response.json()

    const newSuggestions = data.alternatives.map((alt) => alt.text)
    if (data.suggested) {
      newSuggestions.unshift(data.suggested.text)
    }
    if (
      data['corrected-query'] &&
      data['corrected-query'] !== newSuggestions[0]
    ) {
      newSuggestions.unshift(data['corrected-query'])
    }
    if (newSuggestions.length === 0) {
      newSuggestions.push(query)
    }
    setOptions(newSuggestions)
    setCorrectedQuery(data['corrected-query'])
  }, [query, searchSpringAPIUrl, searchSpringSiteId])

  useEffect(() => {
    if (query.length > 2) {
      getAutocomplete()
    } else {
      setOptions([])
      setSuggestedItems([])
      setProductResults([])
      setCorrectedQuery('')
    }
  }, [query])

  useEffect(() => {
    const getSuggestedItems = async () => {
      let suggestedItemsResponse
      let suggestedItemsData

      suggestedItemsQuery.set('q', options[0])
      suggestedItemsResponse = await fetch(
        `${searchSpringAPIUrl}search/autocomplete.json?${suggestedItemsQuery}`
      )
      suggestedItemsData = await suggestedItemsResponse.json()
      checkCustomRedirect(suggestedItemsData)
      return suggestedItemsData.results || []
    }
    if (options.length > 0) {
      getSuggestedItems().then((items) => {
        if (items.length > 0) {
          setSuggestedItems(items)
        }
      })
    }
  }, [options])

  useEffect(() => {}, [productResults, query])
  if (isMobile) {
    return (
      <>
        <div className="navigation__mobile--search-screen--search-bar k-px-5">
          <SearchInput
            wrapperClass="navigation__mobile--search-screen--input-wrapper"
            handleKeypress={handleKeypress}
            query={query}
            setQuery={setQuery}
          />
          <Close classnames="navigation__mobile__search-screen__close" />
        </div>
        <div className="navigation__mobile--search-screen--content k-px-5">
          {options.length <= 0 && (
            <div>
              <PopularSearches
                titleClassName="k-pb-3 k-mb-0 k-text--lg"
                linkClassName="k-text--md k-pb-1"
                baseUrlShop={baseUrlShop}
                popularSearches={popularSearches}
              />
            </div>
          )}
          {options.length > 0 && (
            <>
              <p className="k-pb-3 k-mb-0 k-text--lg">Top Suggestions</p>
              {options
                .filter((option, i, self) => self.indexOf(option) === i)
                .map((option, i) => (
                  <p className="k-text--md k-mb-1">
                    <AutocompleteSuggestion
                      query={correctedQuery || query}
                      redirectURL={redirectURL}
                      suggestion={option}
                      baseUrlShop={baseUrlShop}
                      key={i}
                    />
                  </p>
                ))}
              <div className="navigation__mobile--search-screen--products k-mt-8">
                {suggestedItems &&
                  suggestedItems.map((suggestedItem) => (
                    <ProductCard
                      key={suggestedItem.id}
                      storefrontAPIKey={storefrontAPIKey}
                      storefrontAPIPath={storefrontAPIPath}
                      product={suggestedItem}
                      baseUrlShop={baseUrlShop}
                      productResults={productResults}
                      setProductResults={setProductResults}
                      isMobile={true}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="desktop-input-wrapper">
        <SearchInput
          wrapperClass="search-bar-wrapper"
          handleKeypress={handleKeypress}
          query={query}
          setQuery={setQuery}
          inputClass={'search-bar-wrapper__input'}
        />
        <div
          className="k-text--sm k-px-3 desktop-input-wrapper__clear"
          onClick={() => setQuery('')}
        >
          Clear
        </div>
      </div>
      <div className="search__closeButton">
        <Close />
      </div>

      {options.length <= 0 ? (
        <div
          className={`search__focusContainer search__focusContainer__popular-searches`}
        >
          <div className=" k-mt-4 k-pt-4">
            <PopularSearches
              titleClassName="k-pb-3 k-mb-0 k-text--lg"
              linkClassName="k-text--md k-pb-1"
              baseUrlShop={baseUrlShop}
              popularSearches={popularSearches}
            />
          </div>
        </div>
      ) : (
        <div
          className={`search__focusContainer search__focusContainer--show-items search__focusContainer--open`}
        >
          <div className="search__closeButton">
            <Close />
          </div>

          <div className="search__show-items__wrapper k-mt-6 k-pt-4">
            <>
              <div className="search-bar-wrapper__autocomplete k-py-5 k-mx-8">
                <div className="k-pb-3 k-mb-0 k-text--lg">Top Suggestions</div>
                {options
                  .filter((option, i, self) => self.indexOf(option) === i)
                  .map((option) => (
                    <p className="k-text--sm k-mb-1" key={option}>
                      <AutocompleteSuggestion
                        query={correctedQuery || query}
                        redirectURL={redirectURL}
                        suggestion={option}
                        baseUrlShop={baseUrlShop}
                      />
                    </p>
                  ))}
              </div>
              <div className="search-bar-wrapper__products k-mx-8 k-px-3 k-py-3 k-mb-2">
                {suggestedItems &&
                  suggestedItems.map((suggestedItem) => (
                    <ProductCard
                      key={suggestedItem.id}
                      storefrontAPIKey={storefrontAPIKey}
                      storefrontAPIPath={storefrontAPIPath}
                      product={suggestedItem}
                      baseUrlShop={baseUrlShop}
                      productResults={productResults}
                      setProductResults={setProductResults}
                    />
                  ))}
              </div>
            </>
          </div>
        </div>
      )}
    </>
  )
}

SearchBar.propTypes = {
  storefrontAPIKey: PropTypes.string.isRequired,
  storefrontAPIPath: PropTypes.string.isRequired,
  baseUrlShop: PropTypes.string.isRequired,
  searchSpringAPIUrl: PropTypes.string.isRequired,
  searchSpringSiteId: PropTypes.string.isRequired,
}

export default SearchBar
