import {
  CACHE_DURATION,
  EXPIRES_AT,
  LOGGED_IN_RESPONSE,
  LOGGED_OUT_RESPONSE,
} from './constants'
import { getEnvVar } from './env'
import { getLoggedInStatus, resetCookie } from './session'

const resetStoredResponses = () => {
  localStorage.removeItem('logged_in_response')
  localStorage.removeItem('logged_out_response')
}

const handleVersion = (incomingVersion) => {
  const existingVersion = parseInt(localStorage.getItem('cms_version'))

  if (isNaN(existingVersion) || incomingVersion > existingVersion) {
    localStorage.setItem('cms_version', incomingVersion)
    resetStoredResponses()
  }
}

const setCacheExpiration = () => {
  const expireTime = new Date()
  expireTime.setMinutes(expireTime.getMinutes() + CACHE_DURATION)

  localStorage.setItem(EXPIRES_AT, expireTime.toISOString())
}

export const storeData = (data) => {
  const isLoggedIn = getLoggedInStatus()
  const { navigation, version, banners, footer, is_survey_finished } = data
  const navData = JSON.stringify(navigation)
  let responseToSet = isLoggedIn ? LOGGED_IN_RESPONSE : LOGGED_OUT_RESPONSE

  handleVersion(version)

  if (isLoggedIn && !navigation['mobile-session-status']) {
    // In case cookie is out of sync with actual logged in status, delete cookie + set as logged out response
    resetCookie()
    responseToSet = LOGGED_OUT_RESPONSE
  }

  localStorage.setItem(responseToSet, navData)
  localStorage.setItem('banners', JSON.stringify(banners))
  localStorage.setItem('footer', JSON.stringify(footer))
  localStorage.setItem('is_survey_finished', is_survey_finished)
  setCacheExpiration()
}

export const getItemFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

export const fetchData = async () => {
  return fetch(getEnvVar('apiUrl'), { credentials: 'include' })
    .then((response) => response.json())
    .then((data) => storeData(data))
}
