import ReactDOM from 'react-dom'
import React from 'react'
import { SearchBar } from '../src/index'
import { DESKTOP_WIDTH, DROPDOWN_PADDING } from './constants'
import { getEnvVar } from './env'
import { addSearchListeners } from './listeners'
import { makeVisible, setDisplayNone } from './utils'

const search_bar_props = {
  baseUrlShop: getEnvVar('baseUrlShop'),
  storefrontAPIKey: getEnvVar('storefrontAPIKey'),
  storefrontAPIPath: getEnvVar('storefrontAPIPath'),
  searchSpringAPIUrl: getEnvVar('searchSpringAPIUrl'),
  searchSpringSiteId: getEnvVar('searchSpringSiteId'),
}

export const getSearchElements = () => ({
  fullSearchContainer: document.querySelector('.search__focusContainer'),
  searchBarClose: document.querySelector('.search__closeButton'),
  searchBarCloseMobile: document.querySelector(
    '.navigation__mobile__search-screen__close'
  ),
  searchBarContainerDesktop: document.querySelector('#searchContainer'),
  searchBarContainerMobile: document.querySelector('#searchContainerMobile'),
  searchBarInput: document.querySelector('.search-bar-wrapper__input'),
  searchBarMobileContent: document.querySelector(
    '.navigation__mobile--search-screen--content'
  ),
  searchBarMobileInput: document.querySelector('#searchBarMobileInput'),
  searchBarOverlay: document.querySelector('.search-bar-overlay'),
  searchBarToggleMobile: document.querySelector('.nav-link__mobile--search'),
  searchBarWrapper: document.querySelector('.search-bar-wrapper'),
  searchIconDesktop: document.querySelector('.bottom-row__links-right__search'),
})

const styleContent = () => {
  const { searchBarMobileContent } = getSearchElements()

  if (searchBarMobileContent) {
    makeVisible(searchBarMobileContent)
    searchBarMobileContent.style.top = `${DROPDOWN_PADDING}px`
  }
}

const renderMobileSearch = () => {
  const { searchBarContainerMobile, searchBarMobileContent } =
    getSearchElements()

  if (searchBarContainerMobile) {
    ReactDOM.render(
      React.createElement(SearchBar, search_bar_props),
      searchBarContainerMobile
    )

    styleContent()
  }
}

const renderDesktopSearch = () => {
  const { searchBarContainerDesktop } = getSearchElements()

  if (searchBarContainerDesktop) {
    ReactDOM.render(
      React.createElement(SearchBar, search_bar_props),
      searchBarContainerDesktop
    )
  }
}

export const renderSearch = () => {
  if (window.innerWidth > DESKTOP_WIDTH) {
    renderDesktopSearch()
  } else {
    renderMobileSearch()
  }
  addSearchListeners()
}

export const closeDesktopSearch = () => {
  const {
    fullSearchContainer,
    searchBarClose,
    searchBarContainerDesktop,
    searchBarInput,
    searchBarOverlay,
    searchBarWrapper,
  } = getSearchElements()

  searchBarContainerDesktop.classList.remove('search--focused')
  searchBarWrapper.classList.remove('search-bar-wrapper--focused')
  searchBarInput.classList.remove('search-bar-wrapper--focused__input')
  fullSearchContainer.classList.remove('search__focusContainer--open')
  setDisplayNone(fullSearchContainer)
  searchBarClose.classList.remove('search__closeButton--show')
  searchBarOverlay.classList.remove('search-bar-overlay--open')
}
