import 'regenerator-runtime/runtime'
// import { renderSearch } from './search'
import {
  insertBannerHTML,
  insertCartItemCount,
  insertMyAccountHTML,
  insertNavLinkHTML,
  insertSignInHTML,
  insertTbybHTML,
  insertMobileFeaturedContent,
  insertDesktopFeaturedContent,
  insertMobileDiscountLine,
  insertSeasonalHTML,
  insertQuizHTML,
  insertShopAllHTML,
} from './utils'
import { updateCartCount } from './cart'
import {
  addDesktopDropdownListeners,
  addMobileMenuListeners,
  addTbybListeners,
} from './listeners'
import {
  EXPIRES_AT,
  LOGGED_IN_RESPONSE,
  LOGGED_OUT_RESPONSE,
} from './constants'
import { fetchData, getItemFromLocalStorage } from './data'
import { getLoggedInStatus } from './session'
import { checkCartChanged } from './utils/checkCartChanged'
import {
  checkIfFooterIsReady,
  setLogoImage,
  enableNewsletterInput,
  insertRewardsHTML,
} from './utils/insertHTML'

export const appendSignInData = (
  mobileSessionData,
  desktopSessionData,
  surveyFinished
) => {
  const isLoggedIn = getLoggedInStatus()
  if (isLoggedIn) {
    insertMyAccountHTML(mobileSessionData, desktopSessionData)
    surveyFinished ? insertSeasonalHTML() : insertQuizHTML()
  } else {
    insertSignInHTML()
    insertQuizHTML()
  }
}

const appendNavData = async () => {
  const isLoggedIn = getLoggedInStatus()
  const response = isLoggedIn ? LOGGED_IN_RESPONSE : LOGGED_OUT_RESPONSE
  const navData = getItemFromLocalStorage(response)
  const tbybData = navData['desktop-callout']
  const mobileSessionData = navData['mobile-session-status']
  const desktopSessionData = navData['desktop-session-status']
  const mobileDiscountLine = navData['mobile-discount-line']
  const tbybStaticDataMobile = navData['mobile-callout-static']
  const tbybStaticDataDesktop = navData['desktop-callout-static']
  const rewardsData = navData['rewards-data']

  const bannerData = getItemFromLocalStorage('banners')
  const bannerMessages = bannerData['messages']

  bannerMessages.length > 0 && insertBannerHTML(bannerData)

  const footerData = getItemFromLocalStorage('footer')
  checkIfFooterIsReady(footerData)

  const surveyFinished = getItemFromLocalStorage('is_survey_finished')

  setLogoImage()

  insertTbybHTML(tbybData)
  tbybStaticDataDesktop && insertDesktopFeaturedContent(tbybStaticDataDesktop)
  tbybStaticDataMobile && insertMobileFeaturedContent(tbybStaticDataMobile)
  insertNavLinkHTML(navData)
  //insertCartItemCount()
  //updateCartCount()
  window.setInterval(checkCartChanged, 250)
  appendSignInData(mobileSessionData, desktopSessionData, surveyFinished)
  insertMobileDiscountLine(mobileDiscountLine)
  insertRewardsHTML(rewardsData)
}

export const initNav = () => {
  const prevExpTime = Date.parse(localStorage.getItem(EXPIRES_AT))
  const isNavExpired = prevExpTime < Date.now()
  const isLoggedIn = getLoggedInStatus()
  const response = isLoggedIn ? LOGGED_IN_RESPONSE : LOGGED_OUT_RESPONSE
  const isResponseStored = !!localStorage.getItem(response)
  const isFooterStored = !!localStorage.getItem('footer')
  const shouldFetchData =
    isNaN(prevExpTime) || isNavExpired || !isResponseStored || !isFooterStored

  enableNewsletterInput()

  return Promise.resolve()
    .then(shouldFetchData && fetchData)
    .then(appendNavData)
    .then(() => {
      addTbybListeners()
      addMobileMenuListeners()
      // renderSearch()
      insertShopAllHTML()
      addDesktopDropdownListeners()
    })
}

initNav()
