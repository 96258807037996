import { setTransition } from './styles'

export const addPromoBannerEffects = (container, amountOfMessages) => {
  if (amountOfMessages > 1) {
    const firstMessage =
      container.children.length > 0 ? container.children[0] : null
    let index = 1
    window.setInterval(function () {
      setTransition(container, 'transform 1.5s ease-out 0s')

      if (index === amountOfMessages) {
        // move first item to last position to create carousel effect
        firstMessage.style.transform = `translate(${amountOfMessages * 100}vw)`
        let translate = `-${index * 100}vw`
        container.style.transform = `translate(${translate})`

        // reset first item to position 0
        window.setTimeout(function () {
          setTransition(container, '')
          firstMessage.style.transform = `translate(0)`
          container.style.transform = 'translate(0vw)'
        }, 3000)

        index = 1
        return
      }

      container.style.transform = `translate(-${index * 100}vw)`
      index += 1
    }, 8000)
  }
}
