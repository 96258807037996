import { hideNavDropdown } from '../desktopDropdown'
import { closeDesktopSearch, getSearchElements, renderSearch } from '../search'
import { closeOverlay, setDisplayBlock, setPosUnset } from '../utils'

const checkVisualViewport = () => {
  const { searchBarMobileContent } = getSearchElements()
  if (searchBarMobileContent) {
    searchBarMobileContent.style.height = `${
      window.visualViewport.height + 10
    }px`
  }
}

export const addSearchResizeListeners = () => {
  window.addEventListener('resize', renderSearch)
  window.visualViewport &&
    window.visualViewport.addEventListener('resize', checkVisualViewport)
}

const mobileNavbar = document.querySelector('.navigation__mobile')

const addBodyScroll = () => {
  document.body.style.position = ''
}

const removeBodyScroll = () => {
  document.body.style.position = 'fixed'
}

const focusAndOpenKeyboard = (el, timeout) => {
  if (!timeout) {
    timeout = 100
  }
  if (el) {
    // Align temp input element approximately where the input element is
    // so the cursor doesn't jump around
    var tempEl = document.createElement('input')
    tempEl.style.position = 'absolute'
    tempEl.style.top = el.offsetTop + 7 + 'px'
    tempEl.style.left = el.offsetLeft + 'px'
    tempEl.style.height = 0
    tempEl.style.opacity = 0
    // Put this temp element as a child of the page <body> and focus on it
    document.body.appendChild(tempEl)
    tempEl.focus()

    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(function () {
      el.focus()
      el.click()
      // Remove the temp element
      document.body.removeChild(tempEl)
    }, timeout)
  }
}

const searchBarClickEvent = () => {
  const { searchBarContainerMobile, searchBarMobileInput } = getSearchElements()

  searchBarContainerMobile.classList.add(
    'navigation__mobile--search-screen--open'
  )
  setPosUnset(mobileNavbar)
  removeBodyScroll()
  searchBarMobileInput.focus()
  focusAndOpenKeyboard(searchBarMobileInput, 800)
}

const scrollToTop = () => {
  scrollTo(0, 0)
}

export const addMobileSearchOpenListeners = () => {
  const { searchBarMobileInput, searchBarToggleMobile } = getSearchElements()

  searchBarMobileInput &&
    searchBarMobileInput.addEventListener('focus', scrollToTop)

  searchBarToggleMobile &&
    searchBarToggleMobile.addEventListener('click', searchBarClickEvent)
}

export const addMobileSearchCloseListeners = () => {
  const { searchBarCloseMobile, searchBarContainerMobile } = getSearchElements()

  searchBarCloseMobile &&
    searchBarCloseMobile.addEventListener('click', () => {
      searchBarContainerMobile.classList.remove(
        'navigation__mobile--search-screen--open'
      )
      closeOverlay('menu__overlay')
      mobileNavbar.style.position = ''
      addBodyScroll()
    })
}

const openDesktopSearch = () => {
  const {
    fullSearchContainer,
    searchBarClose,
    searchBarContainerDesktop,
    searchBarInput,
    searchBarOverlay,
    searchBarWrapper,
  } = getSearchElements()

  hideNavDropdown()
  searchBarContainerDesktop.classList.add('search--focused')
  searchBarWrapper.classList.add('search-bar-wrapper--focused')
  searchBarInput.classList.add('search-bar-wrapper--focused__input')
  searchBarClose.classList.add('search__closeButton--show')
  searchBarOverlay.classList.add('search-bar-overlay--open')
  fullSearchContainer.classList.add(
    'search__focusContainer__popular-searches--open'
  )
  setDisplayBlock(fullSearchContainer)
}

export const addDesktopOpenListeners = () => {
  const { searchBarInput, searchIconDesktop } = getSearchElements()

  searchBarInput && searchBarInput.addEventListener('focus', openDesktopSearch)

  searchIconDesktop &&
    searchIconDesktop.addEventListener('click', openDesktopSearch)
}

export const addDesktopInputListeners = () => {
  const { fullSearchContainer, searchBarInput } = getSearchElements()

  searchBarInput &&
    searchBarInput.addEventListener('input', (e) => {
      if (e.target.value.length <= 2) {
        setDisplayBlock(fullSearchContainer)
      }
    })
}

export const addDesktopCloseListeners = () => {
  const { searchBarClose, searchBarOverlay } = getSearchElements()

  searchBarClose &&
    [searchBarClose, searchBarOverlay].forEach((element) => {
      element.addEventListener('click', closeDesktopSearch)
    })
}
