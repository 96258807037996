import { ENV } from './env'
import { LOGGED_IN } from './constants'

export const getCookie = (n) => {
  let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`)
  return a ? a[1] : ''
}

export const resetCookie = () => {
  document.cookie =
    'dia_session_status=;path=/;expires=' + new Date(0).toUTCString()
}

const getSessionStatus = () => {
  const cookie = getCookie('dia_session_status')
  if (cookie.length > 0) {
    return JSON.parse(decodeURIComponent(cookie))
  }
  return {
    test: { logged_in: false },
    development: { logged_in: false },
    staging: { logged_in: false },
    production: { logged_in: false },
  }
}

export const getLoggedInStatus = () => {
  const sessionStatus = getSessionStatus()
  return sessionStatus && sessionStatus[ENV]
    ? sessionStatus[ENV][LOGGED_IN]
    : false
}

export const getCartCountFromCookie = () => {
  const cartCountCookie = getCookie('shopping_cart')

  if (cartCountCookie) {
    return JSON.parse(decodeURIComponent(cartCountCookie))['item_count']
  }
  return 0
}
