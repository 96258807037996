import React from 'react'
import PropTypes from 'prop-types'

const AutocompleteSuggestion = ({
  query,
  suggestion,
  redirectURL,
  baseUrlShop,
}) => {
  let beforeQuery = ''
  let afterQuery = ''
  const position = suggestion.toLowerCase().search(query.toLowerCase())
  if (position > -1) {
    beforeQuery = suggestion.slice(0, position)
    afterQuery = suggestion.slice(beforeQuery.length + query.length)
  }

  let url = `${baseUrlShop}/search/?q=${encodeURIComponent(suggestion)}`
  if (redirectURL && suggestion === query) {
    url = redirectURL
  }

  return (
    <a className="search-bar-wrapper__autocomplete__link" href={url}>
      {beforeQuery}
      <span className="k-text--bold">{query}</span>
      {afterQuery}
    </a>
  )
}

AutocompleteSuggestion.propTypes = {
  query: PropTypes.string.isRequired,
  suggestion: PropTypes.string.isRequired,
  baseUrlShop: PropTypes.string.isRequired,
  redirectURL: PropTypes.string,
}

export default AutocompleteSuggestion
