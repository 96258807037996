import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import useMedia from '../utils/useMediaQuery'
import Tooltip from './Tooltip'
import { Camera } from './icons'

const TransparentButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  display: flex;
`

const Header = styled.div`
  display: flex;
  width: 100%;
  margin-top: -12px;
  height: 30px;
  justify-content: end;
  align-items: center;

  & > svg {
    cursor: pointer;
    margin-right: 0 !important;
  }
`

const TooltipContent = styled(Tooltip.Content)`
  min-width: 242px;
  border-radius: 2px;
`

const PhotoDescription = styled.p`
  text-align: center;
  color: #aaaaaa;
`

const PhotoUploadButton = styled.button`
  font-family: 'CircularXX';
  display: flex;
  height: 36px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  background-color: #262626;
  color: white;
  width: fit-content;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0;
`

const SearchInput = ({
  inputClass,
  wrapperClass,
  handleKeypress,
  query,
  setQuery,
}) => {
  const [showTooltip, setShowTooltip] = useState()
  const urlParams = new URLSearchParams(window.location.search)
  const syteAi = urlParams.get('sai')
  const showSyteAi = syteAi === '1'

  const isMobile = useMedia(
    {
      '(max-width: 1024px)': true,
    },
    false
  )

  return (
    <div className={wrapperClass}>
      <div className={`${wrapperClass}--animation-left`}></div>
      <div className={`${wrapperClass}--animation-center`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <input
          className={inputClass}
          onKeyPress={handleKeypress}
          placeholder="Search"
          type="text"
          name="query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          id="searchBarMobileInput"
          title="search"
          aria-label="search"
        />
        {showSyteAi && isMobile && (
          <TransparentButton
            type="button"
            className="--syte-start-camera-upload"
            href="javascript:void(0);"
            data-camera-button-placement="search-bar"
          >
            <Camera />
          </TransparentButton>
        )}
        {showSyteAi && !isMobile && (
          <Tooltip show={showTooltip}>
            <Tooltip.Trigger>
              <TransparentButton data-camera-button-placement="tooltip">
                <Camera />
              </TransparentButton>
            </Tooltip.Trigger>
            <TooltipContent>
              <Header />
              <p className="k-hr-4 k-my-0">NEW!</p>
              <p className="k-text--md k-my-0">Visual Search</p>
              <PhotoDescription className="k-text--xs k-mt-1 k-mb-0">
                Take a photo or upload an image to find similar items
              </PhotoDescription>
              <PhotoUploadButton
                type="button"
                className="k-text--sm --syte-start-camera-upload"
                href="javascript:void(0);"
                data-camera-button-placement="search-bar"
              >
                Start
              </PhotoUploadButton>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <div
        className={`${wrapperClass}--animation-right`}
        style={showSyteAi ? { width: '10px' } : {}}
      ></div>
    </div>
  )
}

SearchInput.propTypes = {
  inputClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  handleKeypress: PropTypes.func.isRequired,
  query: PropTypes.string,
  setQuery: PropTypes.func,
}

export default SearchInput
