import React from 'react'

const Camera = () => (
  <svg
    className="k-mx-0"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0097 6.93684L14.5939 8.32463C14.664 8.49033 14.851 8.5939 15.038 8.5939H16.6738C17.4683 8.5939 18.0759 9.13245 18.0759 9.8367V14.8079C18.0759 15.5121 17.4683 16.0507 16.6738 16.0507H7.32617C6.53163 16.0507 5.92403 15.5121 5.92403 14.8079V9.8367C5.92403 9.13245 6.53163 8.5939 7.32617 8.5939H8.96201C9.17233 8.5939 9.33591 8.49033 9.40602 8.32463L9.99024 6.93684H14.0097ZM8.14953 6.8724H7.32617C5.78193 6.8724 4.20251 7.99236 4.20251 9.8367V14.8079C4.20251 16.6522 5.78193 17.7722 7.32617 17.7722H16.6738C18.218 17.7722 19.7974 16.6522 19.7974 14.8079V9.8367C19.7974 7.99236 18.218 6.8724 16.6738 6.8724H15.8504L15.5964 6.2689C15.3276 5.63049 14.7024 5.21533 14.0097 5.21533H9.99024C9.29756 5.21533 8.67234 5.63049 8.40359 6.2689L8.14953 6.8724Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13.3163C12.7271 13.3163 13.3165 12.7269 13.3165 11.9999C13.3165 11.2728 12.7271 10.6834 12 10.6834C11.273 10.6834 10.6836 11.2728 10.6836 11.9999C10.6836 12.7269 11.273 13.3163 12 13.3163ZM12 15.0378C13.6778 15.0378 15.038 13.6777 15.038 11.9999C15.038 10.3221 13.6778 8.96191 12 8.96191C10.3222 8.96191 8.96204 10.3221 8.96204 11.9999C8.96204 13.6777 10.3222 15.0378 12 15.0378Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2785 3.8987C22.2785 2.69627 21.3037 1.7215 20.1013 1.7215L16.0506 1.7215L16.0506 -3.47475e-07L20.1013 -1.70418e-07C22.2545 -7.62986e-08 24 1.74551 24 3.8987L24 7.9493L22.2785 7.9493L22.2785 3.8987Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.72152 3.8987C1.72152 2.69627 2.69629 1.7215 3.89873 1.7215L7.94936 1.72151L7.94936 3.47478e-07L3.89873 1.70419e-07C1.74552 7.62992e-08 -7.62986e-08 1.74551 -1.70418e-07 3.8987L-3.47475e-07 7.9493L1.72152 7.9493L1.72152 3.8987Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1013 22.2785C21.3037 22.2785 22.2785 21.3037 22.2785 20.1013L22.2785 16.0507L24 16.0507L24 20.1013C24 22.2545 22.2545 24 20.1013 24L16.0506 24L16.0506 22.2785L20.1013 22.2785Z"
      fill="#262626"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.72152 20.1013C1.72152 21.3037 2.69629 22.2785 3.89873 22.2785L7.94936 22.2785L7.94936 24L3.89873 24C1.74552 24 2.28898e-07 22.2545 5.11257e-07 20.1013L1.04243e-06 16.0507L1.72152 16.0507L1.72152 20.1013Z"
      fill="#262626"
    />
  </svg>
)

export default Camera
