import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import AnimatedSpinner from './AnimatedSpinner'

const SpinnerContainer = styled.div(`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`)

const ProductCardContainer = styled.div(`
  font-size: 10px;
  padding: 1.75em;
  padding-top: calc((1.25 * 25% - (1.5em * 3 / 4)) + 8.375em);
  height: 100%;
  width: 30%;
  text-decoration: none;
  position: relative;

  .product {
    &__bg {
      opacity: 0;
      box-shadow: 0 0 11px rgba(38, 38, 38, 0.25);
      background-color: white;
      position: absolute;
      top: -1.75em;
      left: -1.3em;
      width: calc(100% + 2.5em);
      height: calc(100% + 2.5em);
      transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      z-index: -1;

      &--show {
        opacity: 1;
      }
    }

    &__card {
      position: absolute;
      width: 80%;
      top: 0;
      left: 0;
      padding: 16px;
    }

    &__thumbnail {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 0.625em;
        transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);

        &:nth-child(2) {
          position: absolute;
          left: 0;

          &:hover {
            opacity: 0;
          }
        }
      }
    }

    &__summary {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    &__brand {
      margin-bottom: 2px;
    }

    &__name,
    &__price {
      margin: 0;
      font-size: 14px;
    }

    &__colors-available {
      margin: 0;
      color: #767676; //$K-COLOR-DARK-GRAY
      transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);

      &--hidden {
        opacity: 0;
      }
    }

    &__sizes {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .swatch-image {
    width: calc((24 / 16) * 1.5em);
    height: calc((24 / 16) * 1.5em);
    border-radius: 50%;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 0.625em;
    min-height: 1.6em;
    background-color: white;
    padding: 2px;

    &--active {
      border: 1px solid #767676;
    }
  }
  
  .size--in-stock {
    cursor: pointer;
	  border: 1px solid black;
	  color: black;
	  background-color: white;
	  text-decoration: none;
	
	  &:hover {
	    background-color: black;
	    text-decoration: none;
	
	    .size {
	      color: white;
	    }
	  }
  }
`)

const SizeContainer = styled.a(`
  text-align: center;

  margin: 3px;
  padding: 5px;
  transition: background-color 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 10px;
  text-decoration: none;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  cursor: default;

  .size {
    text-decoration: none;
    margin: 0;
  }
`)

const Size = ({ size, inStock, pdpUrl }) => {
  let width
  if (size.length >= 6) {
    width = 100
  } else {
    width = 33.3
  }

  return (
    <SizeContainer
      className={classNames({ 'size--in-stock': inStock })}
      width={width}
      href={inStock > 0 ? pdpUrl : '#'}
    >
      <p className="size">{size}</p>
    </SizeContainer>
  )
}

Size.propTypes = {
  size: PropTypes.string.isRequired,
  inStock: PropTypes.bool.isRequired,
  pdpUrl: PropTypes.string.isRequired,
}

const transformProductResponse = ({ node }) => {
  const transformedProduct = {
    id: node.id,
    productTitle: node.title,
    title: node.variants.edges[0].node.selectedOptions[0].value,
    thumbnails: {
      primary: {
        url: '',
        altText: '',
      },
    },
    swatchImg: '',
    sizes: {},
    onlineStoreUrl: node.onlineStoreUrl,
  }

  let thumbnailSet = false
  node.images.edges.forEach((image) => {
    if (
      !thumbnailSet &&
      image.node.altText &&
      !image.node.altText.includes('color-swatch')
    ) {
      transformedProduct.thumbnails.primary = image.node
      thumbnailSet = true
    }

    if (image.node.altText && image.node.altText.includes('color-swatch')) {
      transformedProduct.swatchImg = image.node.url
    }
  })

  node.variants.edges.forEach((variant) => {
    const size = variant.node.selectedOptions[1].value
    transformedProduct.sizes[size] = variant.node.availableForSale
  })

  return transformedProduct
}

/**
 * @param storefrontAPIKey
 * @param storefrontAPIPath
 * @param product - Product object returned from the searchspring API
 * @param baseUrlShop
 * @param productResults
 * @param setProductResults - Suggested items from the searchbar may not exist in storefront, so need to be able to tell
 * searchbar there were actually no results for the products it suggested to us.
 * @param isMobile - If the product cards are being rendered on mobile view
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProductCard({
  storefrontAPIKey,
  storefrontAPIPath,
  product,
  baseUrlShop,
  productResults,
  setProductResults,
  isMobile,
}) {
  const [inHover, setHover] = useState(false)
  const [productStyles, setProductStyles] = useState([])
  const [selectedStyleId, setSelectedStyleId] = useState('')
  const [styleObject, setStyleObject] = useState('')
  const [loading, setLoading] = useState(false)

  const QUERY = `
  {
    products(query: "tag:${product.tags_dia_product_id}", first: 20) {
        edges {
          node {
            id
            title
            onlineStoreUrl
            images(first: 20) {
              edges {
                node {
                  altText
                  url
                }
              }
            }
            variants(first: 20) {
              edges {
                node {
                  availableForSale
                  selectedOptions {
                    name
                    value
                  }
                  image {
                    altText
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  useEffect(() => {
    const getProductStyles = async () => {
      setLoading(true)
      fetch(storefrontAPIPath, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/graphql',
          'X-Shopify-Storefront-Access-Token': storefrontAPIKey,
        },
        method: 'POST',
        body: QUERY,
      })
        .then((res) => res.json())
        .then(({ data }) => {
          const newProductStyles = data.products.edges
            .map((node) => transformProductResponse({ node: node.node }))
            .filter((style) => style.onlineStoreUrl)
          const productStyle = newProductStyles.find(
            (prod) => prod.id.split('/').pop() === product.uid
          )
          setStyleObject(productStyle)
          setProductStyles(newProductStyles)
          if (newProductStyles.length > 0) {
            setProductResults([...productResults, newProductStyles])
          }
        })
      setLoading(false)
    }

    getProductStyles()
  }, [product])

  useEffect(() => {
    productStyles.forEach((style) => {
      if (style.id === selectedStyleId) {
        setStyleObject(style)
      }
    })
  }, [productStyles, selectedStyleId])

  if (loading) {
    return (
      <SpinnerContainer>
        <AnimatedSpinner width={50} />
      </SpinnerContainer>
    )
  }

  if (!styleObject) {
    return <></>
  }

  return (
    <ProductCardContainer
      className={classNames('product', 'k-mb-4', {
        'product--hover': inHover,
      })}
      onMouseEnter={() => !isMobile && setHover(true)}
      onMouseLeave={() => !isMobile && setHover(false)}
    >
      <div className="product__card">
        <div
          className={classNames('product__bg', {
            'product__bg--show': inHover,
          })}
        />
        <div className="product__thumbnail">
          <a href={styleObject.onlineStoreUrl}>
            {/* eslint-disable */}
            <img
              src={styleObject.thumbnails.primary.url}
              alt={styleObject.thumbnails.primary.altText}
            />
            {/* eslint-enable */}
          </a>
        </div>
        <a href={styleObject.onlineStoreUrl} className="product__summary">
          {/* eslint-disable-next-line react/no-danger */}
          <p
            className="k-text--xxs product__brand"
            dangerouslySetInnerHTML={{ __html: product.tags_dia_brand_name }}
          />
          <p
            className="k-text--xs k-text--bold product__name"
            dangerouslySetInnerHTML={{ __html: product.ss_name }}
          />
          <p className="product__price">${product.price}</p>
        </a>
        <p
          className={classNames('k-text--xxs product__colors-available', {
            'product__colors-available--hidden': inHover,
          })}
        >
          {productStyles.length} colors
        </p>
        <div style={{ visibility: inHover ? 'initial' : 'hidden' }}>
          <p className="k-text--xxxs k-text--bold text-uppercase">
            {styleObject.title}
          </p>
          {productStyles.map((style) => (
            // eslint-disable-next-line
            <img
              key={style.id}
              className={classNames('swatch-image', {
                'swatch-image--active': styleObject.id === style.id,
              })}
              alt=""
              src={style.swatchImg}
              onClick={() => setSelectedStyleId(style.id)}
            />
          ))}
          <p className="k-text--xxxs k-text--bold text-uppercase">Quick Shop</p>
          <div className="product__sizes">
            {/* eslint-disable-next-line array-callback-return */}
            {Object.entries(styleObject.sizes).map(([size, inStock]) => (
              <Size
                key={size}
                size={size}
                inStock={inStock}
                pdpUrl={styleObject.onlineStoreUrl}
              />
            ))}
          </div>
        </div>
      </div>
    </ProductCardContainer>
  )
}

ProductCard.propTypes = {
  storefrontAPIKey: PropTypes.string.isRequired,
  storefrontAPIPath: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  baseUrlShop: PropTypes.string.isRequired,
  productResults: PropTypes.array.isRequired,
  setProductResults: PropTypes.func.isRequired,
  styleIndex: PropTypes.number,
  isMobile: PropTypes.bool,
}
