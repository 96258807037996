import { getCartCountFromCookie } from './session'
import { setDisplayFlex, setDisplayNone } from './utils'

const getCartElements = () => ({
  desktopCartCountContainerEl: document.querySelector(
    '.nav-link__desktop__cart-count-container'
  ),
  desktopCartCountEl: document.querySelector('.nav-link__desktop__cart-count'),
  mobileCartCountContainerEl: document.querySelector(
    '.nav-link__mobile__cart-count-container'
  ),
  mobileCartCountEl: document.querySelector('.nav-link__mobile__cart-count'),
  stickyCartCountEl: document.querySelector(
    '.bottom-row__links-right__cart-count'
  ),
  stickyCartCountContainerEl: document.querySelector(
    '.bottom-row__links-right__cart-count-container'
  ),
})

export const updateCartCount = () => {
  const {
    desktopCartCountContainerEl,
    desktopCartCountEl,
    mobileCartCountContainerEl,
    mobileCartCountEl,
    stickyCartCountEl,
    stickyCartCountContainerEl,
  } = getCartElements()

  const cartCount = getCartCountFromCookie()

  if (cartCount > 0) {
    setDisplayFlex(desktopCartCountContainerEl)
    desktopCartCountEl.innerHTML = cartCount
    setDisplayFlex(mobileCartCountContainerEl)
    mobileCartCountEl.innerHTML = cartCount
    setDisplayFlex(stickyCartCountContainerEl)
    stickyCartCountEl.innerHTML = cartCount
    return
  }
  setDisplayNone(desktopCartCountContainerEl)
  setDisplayNone(mobileCartCountContainerEl)
  setDisplayNone(stickyCartCountContainerEl)
}
