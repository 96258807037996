import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Close = ({ classnames }) => (
  <svg
    className={classNames('feather feather-x', classnames)}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
)

Close.propTypes = {
  classnames: PropTypes.string,
}

export default Close
