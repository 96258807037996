import { updateCartCount } from '../cart'

export const checkCartChanged = (function () {
  let lastCookie = document.cookie
  return function () {
    const currentCookie = document.cookie
    if (currentCookie !== lastCookie) {
      updateCartCount()
      lastCookie = currentCookie
    }
  }
})()
