import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Link = styled.a(`
  text-decoration: none;
`)

const PopularSearches = ({
  titleClassName,
  linkClassName,
  baseUrlShop,
  popularSearches,
}) => {
  return (
    <>
      <p className={titleClassName}>Popular Searches</p>
      {popularSearches.map((searchTerm, i) => (
        <div key={i} className={linkClassName}>
          <Link href={`${baseUrlShop}/${searchTerm.href}`}>
            {searchTerm.name}
          </Link>
        </div>
      ))}
    </>
  )
}

PopularSearches.propTypes = {
  titleClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  baseUrlShop: PropTypes.string.isRequired,
  popularSearches: PropTypes.array.isRequired,
}

export default PopularSearches
