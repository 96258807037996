export const getCurrentLevelFromMenuClick = (element) => {
  let currentElement = element
  let totalClassList = []
  while (currentElement) {
    totalClassList = totalClassList.concat([...currentElement.classList])
    currentElement = currentElement.nextElementSibling
  }
  const nextLevelClass = totalClassList.find((a) => a.includes('level--'))
  if (nextLevelClass) {
    const nextLevelNumber =
      nextLevelClass[nextLevelClass.indexOf('level--') + 'level--'.length]
    return nextLevelNumber - 1
  }
}

export const getCurrentLevelFromBackClick = (backContainer) => {
  return backContainer.dataset.level
}
