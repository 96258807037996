import { useState, useEffect } from 'react'

function useMedia(queriesObj, defaultValue) {
  const match = () => {
    const query = Object.keys(queriesObj).filter(
      (q) => window.matchMedia(q).matches
    )[0]
    return queriesObj[query] || defaultValue
  }

  const [value, set] = useState(match)

  useEffect(() => {
    const handler = () => set(match)
    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [])

  return value
}

export default useMedia
