export { addDesktopDropdownListeners } from './desktopDropdown'
export {
  addMobileBackListener,
  addMobileMenuListeners,
  addMobileSubMenuListeners,
  addMobileAccountNavListener,
} from './mobileMenu'
import {
  addSearchResizeListeners,
  addMobileSearchOpenListeners,
  addMobileSearchCloseListeners,
  addDesktopOpenListeners,
  addDesktopInputListeners,
  addDesktopCloseListeners,
} from './search'
export { addTbybListeners } from './tryBeforeYouBuy'

export const addSearchListeners = () => {
  addSearchResizeListeners()
  addMobileSearchOpenListeners()
  addMobileSearchCloseListeners()
  addDesktopOpenListeners()
  addDesktopInputListeners()
  addDesktopCloseListeners()
}
